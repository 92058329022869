import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import './assets/css/custom.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

let render = () => {
  import('./assets/css/sass/default.scss').then((x) => {
    require('./AppRenderer');
  });
};
render();
